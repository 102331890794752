import React, {useContext, useEffect, useState} from "react";
import Cookies from "js-cookie"; // Import cookies library
import {CartContext} from "../Contexts/CartContext";
import {json, useNavigate} from "react-router-dom";
import {Elements, ExpressCheckoutElement} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {MdDiscount} from "react-icons/md";

import getUserLocale from "get-user-locale";

const Cart = () => {
    const {cartItems, increaseQuantity, decreaseQuantity, totalValue} = useContext(CartContext);
    const navigate = useNavigate();

    const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

    const appearance = {};

    const [clientSecret, setClientSecret] = useState();
    const stripePromise = loadStripe("pk_live_51QbRlIBUlURnMqrRXdhlt7ToQhLl7vCidxOVLuQ020MTsDCY3COhnc7SoOBvWyhhm4K3wgvaCFR2w60jIfamdlKU00hak7cRAa");

    const [payButton, setPayButton] = useState(null);
    const [selectedUrl, setUrl] = useState(null);

    const [couponCode, setCouponCode] = useState(""); // To track the coupon code input
    const [appliedCoupons, setAppliedCoupons] = useState([]); // Array of applied coupons
    const [error, setError] = useState(""); // To show errors for invalid coupons

    const appliedDiscount = appliedCoupons.reduce((total, coupon) => total + coupon.discount, 0); // Calculate cumulative discount
    const discountedPrice = Math.max(totalPrice - totalPrice * (appliedDiscount / 100), 0); // Calculate the discounted price

    const lang = getUserLocale();

    // Load applied coupons from cookies on component mount
    useEffect(() => {
        const savedCoupons = Cookies.get("applied-coupons");
        if (savedCoupons) {
            const parsedCoupons = JSON.parse(savedCoupons);
            if (Array.isArray(parsedCoupons) && parsedCoupons.length > 0) {
                setAppliedCoupons(parsedCoupons); // Set state only if there are valid saved coupons
            }
        }
    }, []);

    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);

        // Set the document title to product name + VendorsWorld
        document.title = `Cart - VendorsWorld`;

        // Clean up (optional)
        return () => {
            document.title = "VendorsWorld"; // Reset to default when the component unmounts
        };
    }, []); // Depend on title to update the title when the product changes

    // Save applied coupons to cookies whenever they change
    useEffect(() => {
        if (appliedCoupons.length > 0) {
            Cookies.set("applied-coupons", JSON.stringify(appliedCoupons), {expires: 7}); // Save for 7 days
        } else {
            Cookies.remove("applied-coupons"); // Remove the cookie if no coupons are applied
        }
    }, [appliedCoupons]);

    useEffect(() => {
        let finalPrice = lang.includes("cs") ? discountedPrice : discountedPrice;
        let currency = lang.includes("cs") ? "czk" : "eur";

        console.log(lang + " " + currency + " " + finalPrice);
    }, [discountedPrice, lang]);

    useEffect(() => {
        let finalPrice = lang.includes("cs") ? discountedPrice : discountedPrice / 25;
        let currency = lang.includes("cs") ? "czk" : "eur";

        fetch('https://api.whustle.com/product-pay2/' + finalPrice + '/' + currency + '/VendorsWorld', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify( { cartItems: items } )
        })
            .then(response => response.json())
            .then(data => {
                setUrl(data.url);
            });
    }, [discountedPrice]);


    const [items, setItems] = useState([]);

    useEffect(() => {
        if (cartItems.length > 0) {
            const updatedItems = cartItems.map((item) => ({
                productTitle: item.title,
                quantity: item.quantity,
                variantTitle: Object.values(item.selectedOptions).map(option => ` | ${option} `).join('') + '|',
                imageUrl: item.img,
            }));

            setItems(updatedItems);
        }
    }, [cartItems]);


    useEffect(() => {
        const updatePaymentIntent = async () => {
            if (totalValue === 0) return;
            let finalPrice = lang.includes("cs") ? discountedPrice : discountedPrice / 25;
            let currency = lang.includes("cs") ? "czk" : "eur";

            try {
                const response = await fetch("https://api.resellerheaven.eu/create-or-update-payment-intent/" + currency, {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({totalAmount: finalPrice}),
                });
                const data = await response.json();
                setClientSecret(data.clientSecret);
                setPayButton(
                    <div key={"" + totalValue}>
                        <Elements stripe={stripePromise} options={{clientSecret, appearance}}>
                            <ExpressCheckoutElement onConfirm={() => console.log("Express Checkout Success")}/>
                        </Elements>
                    </div>
                );
            } catch (error) {
                console.error("Error updating payment intent:", error);
            }
        };

        updatePaymentIntent();
    }, [discountedPrice]);



    const handleApplyCoupon = () => {
        // Define valid coupons
        const validCoupons = {
            "SECRET2024": {discount: 10, code: "SECRET2024"},
            "KBK.WIFI": {discount: 10, code: "KBK.WIFI"}
        };

        // Check if the coupon is valid
        if (validCoupons[couponCode]) {
            // Check if the coupon is already applied
            if (appliedCoupons.some(coupon => coupon.code === couponCode)) {
                setError("Tento kód již byl použit."); // This code has already been applied
                return;
            }

            if (appliedCoupons.some(coupon => coupon.code === "ADAM2000")) {
                if(couponCode === "KLUCIZPRAHY") {
                    setError("Tento kód nelze kombinovat."); // This code has already been applied
                    return;
                }
            }

            if (appliedCoupons.some(coupon => coupon.code === "KLUCIZPRAHY")) {
                if(couponCode === "ADAM2000") {
                    setError("Tento kód nelze kombinovat."); // This code has already been applied
                    return;
                }
            }

            // Add the coupon to the applied coupons array
            setAppliedCoupons([...appliedCoupons, validCoupons[couponCode]]);
            setCouponCode(""); // Clear the input
            setError(""); // Clear previous errors
        } else {
            setError("Neplatný slevový kód"); // Invalid coupon
        }
    };

    const handleRemoveCoupon = (code) => {
        // Remove the coupon from the applied coupons array
        setAppliedCoupons(appliedCoupons.filter(coupon => coupon.code !== code));
    };

    return (
        <div className={"w-full"}>
            <div
                className="w-full md:w-3/5 mx-auto px-4 grid mt-44 mb-8 py-6 bg-transparent rounded-md shadow-md">
                <h2 className="text-2xl font-bold text-gray-800 dark:text-white mb-4">Tvůj Košík</h2>

                {cartItems.length > 0 ? (
                    <div>
                        <table className="w-full text-left">
                            <thead>
                            <tr>
                                <th className="py-2">Product</th>
                                <th className="py-2 text-center">Quantity</th>
                                <th className="py-2 text-right">Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {cartItems.map((item, index) => (
                                <tr key={index} className="border-b border-t border-[#262626]">
                                    <td className="py-4">
                                        <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                                            <button onClick={() => navigate("/products/" + item.handle)}>
                                                <img
                                                    src={item.img}
                                                    alt={item.title}
                                                    className="w-16 h-16 md:w-24 md:h-24 object-cover rounded"
                                                />
                                            </button>
                                            <div className="mt-2 md:mt-0">
                                                <h3 className="text-gray-800 dark:text-white font-semibold">
                                                    {item.title}
                                                </h3>
                                                {item.selectedOptions && (
                                                    <div className="text-sm text-gray-500">
                                                        {Object.entries(item.selectedOptions).map(([key, value]) => (
                                                            <p key={key}>
                                                                {key}: {value}
                                                            </p>
                                                        ))}
                                                    </div>
                                                )}
                                                <p className="text-sm text-gray-500">Cena: {lang.includes("cs") ? item.price + " Kč" : (item.price / 25).toFixed(2) + " €"}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="flex py-8 md:py-4 md:mt-5 mt-11 text-center items-center justify-center">
                                        <div
                                            className="border rounded-xl py-2 md:py-3 grid grid-cols-3 w-16 md:w-32 justify-items-center">
                                            <button
                                                onClick={() => decreaseQuantity(item)}
                                                className="text-white w-full"
                                            >
                                                -
                                            </button>
                                            <span className="text-gray-800 dark:text-white">{item.quantity}</span>
                                            <button
                                                onClick={() => increaseQuantity(item)}
                                                className="text-white w-full"
                                            >
                                                +
                                            </button>
                                        </div>
                                    </td>
                                    <td className="py-4 text-center px-1 md:text-right text-gray-800 dark:text-white w-32">
                                        {lang.includes("cs") ? (item.price * item.quantity).toLocaleString() + " Kč" : ((item.price * item.quantity) / 25).toFixed(2) + " €"}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        <div className="mt-4">
                            <div className="flex items-center space-x-2">
                                <input
                                    type="text"
                                    value={couponCode}
                                    onChange={(e) => setCouponCode(e.target.value)}
                                    placeholder="Zadejte slevový kód"
                                    className="px-4 py-3 rounded-full text-white bg-black border border-white w-2/3 md:w-1/3"
                                />
                                <button
                                    onClick={handleApplyCoupon}
                                    className="px-4 py-3 bg-white text-black hover:bg-gray-200 rounded-full"
                                >
                                    Použít
                                </button>
                            </div>
                            {error && <p className="text-red-500 mt-2">{error}</p>}

                            {/* Display applied coupons */}
                            {appliedCoupons.length > 0 && (
                                <div className="md:flex md:space-x-2 grid grid-cols-2 mt-4">
                                    {appliedCoupons.map((coupon) => (
                                        <div key={coupon.code}
                                             className="flex items-center space-x-2 bg-gray-100 px-4 py-2 rounded-full w-fit mb-2">
                                            <MdDiscount className="text-gray-700"/>
                                            <span className="text-gray-700 text-sm">{coupon.code}</span>
                                            <button
                                                onClick={() => handleRemoveCoupon(coupon.code)}
                                                className="text-gray-500 hover:text-red-500 transition"
                                            >
                                                ×
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className="mt-6">
                            <h3 className="text-xl font-semibold text-gray-800 dark:text-white">Částka Celkem</h3>
                            <div className="flex items-center space-x-4 mt-2">
                                <p className="text-lg font-bold text-gray-800 dark:text-white">
                                    {lang.includes("cs") ? discountedPrice.toLocaleString() + " Kč" : (discountedPrice / 25).toFixed(2) + " €"}
                                </p>
                                {appliedDiscount > 0 && (
                                    <p className="text-lg font-bold text-gray-500 line-through">
                                        {lang.includes("cs") ? totalPrice.toLocaleString() + " Kč" : (totalPrice / 25).toFixed(2) + " €"}
                                    </p>
                                )}
                            </div>
                            {appliedDiscount > 0 && <p className="text-blue-500">Sleva: {appliedDiscount}%</p>}
                            <p className="text-sm text-gray-500">
                                Tax included and shipping calculated at checkout
                            </p>
                        </div>

                        <div className="mt-6 flex flex-col md:w-2/6 space-y-3 md:space-y-2 justify-between">
                            <button
                                onClick={() => navigate("/")}
                                className="px-5 py-3 bg-gray-200 dark:bg-white text-black rounded-full hover:bg-gray-300"
                            >
                                Pokračovat v Nákupu
                            </button>
                            <div>{clientSecret && <div className={"rounded-full shadow-2xl"}>{payButton}</div>}</div>
                            <button
                                className={"px-5 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-500"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = selectedUrl;
                                }}
                            >
                                Zaplatit nyní
                            </button>
                        </div>
                    </div>
                ) : (
                    <p className="text-gray-500 text-center mt-6">Tvůj košík je prázdný</p>
                )}
            </div>
        </div>
    );
};

export default Cart;
